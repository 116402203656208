import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import ImageComponent from "../machineImageComponents/ImageComponent"

const ComponentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 1310px;
  margin: 3rem auto;
  overflow: hidden;
  /* transition-duration: 0.5s; */

  @media (max-width: 1310px) {
    max-width: 1050px;
  }
  @media (max-width: 1050px) {
    max-width: 800px;
  }
  @media (max-width: 800px) {
    max-width: 530px;
  }
  @media (max-width: 530px) {
    max-width: 220px;
  }
`
const ShowMoreBtn = styled.button`
  position: absolute;
  left: 50%;
  bottom: -40px;
  margin-left: -100px;
  width: 200px;
  text-align: center;
  border: 1px solid black;
  background-color: red;
  color: white;
  padding: 0.3rem;
  font-size: 1em;
  cursor: pointer;
`

const Modal = styled.div`
  position: absolute;
  z-index: 2;
  border: 1px solid black;
  width: 500px;
  height: 500px;
  /* background-color: blue; */
  left: 50%;
  margin-left: -250px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  i {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 1.2em;
    color: red;
  }

  @media (max-width: 510px) {
    width: 320px;
    height: 320px;
    margin-left: -160px;
  }
`

const AviationImages = () => {
  const [expandImages, setExpandImages] = useState({
    name: "More",
    chevron: "fas fa-chevron-down",
    height: "430px",
  })
  const [showModal, setShowModal] = useState("none")
  const [modalData, setModalData] = useState({
    image: "",
    model: "",
    pageLink: "",
    application: "",
    appLink: "",
  })

  const handleClick = () => {
    if (expandImages.height === "430px") {
      setExpandImages({
        name: "Less",
        chevron: "fas fa-chevron-up",
        height: "100%",
      })
    } else
      setExpandImages({
        name: "More",
        chevron: "fas fa-chevron-down",
        height: "430px",
      })
  }

  const openModel = e => {
    setShowModal("block")
    setModalData({
      image: e.target.src,
    })
  }

  const closeModel = () => {
    setShowModal("none")
  }
  const data = useStaticQuery(graphql`
    query {
      allContentfulProductImage(filter: { application: { eq: "Aviation" } }) {
        edges {
          node {
            modelNumber
            category
            application
            pageLink
            applicationLink
            image {
              fluid {
                src
              }
            }
          }
        }
      }
    }
  `)
  return (
    <div style={{ maxWidth: "1310px", margin: "0 auto", position: "relative" }}>
      <h2 style={{ paddingLeft: "1rem", borderBottom: "2px solid red" }}>
        Image Gallery
      </h2>
      <Modal style={{ display: showModal }}>
        <img src={modalData.image} alt={modalData.model} />
        <i onClick={closeModel} className="fas fa-window-close" />
      </Modal>
      <ComponentContainer style={{ height: expandImages.height }}>
        {data.allContentfulProductImage.edges.map(edge => {
          return (
            <ImageComponent
              openModel={openModel}
              imageSrc={edge.node.image.fluid.src}
              imageAlt={edge.node.modelNumber}
              modelNo={edge.node.modelNumber}
              application={edge.node.application}
              modelLink={edge.node.pageLink}
              appLink={edge.node.applicationLink}
              data-link={edge.node.pageLink}
              data-applink={edge.node.applicationLink}
              data-application={edge.node.application}
              data-model={edge.node.modelNumber}
              // displayExpand="none"
            />
          )
        })}
      </ComponentContainer>
      <ShowMoreBtn onClick={handleClick}>
        Show {expandImages.name} <i className={expandImages.chevron} />
      </ShowMoreBtn>
    </div>
  )
}

export default AviationImages
